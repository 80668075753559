import "../NewsTileComponent/NewsTileComponent.scss";
import React from "react";
import Stack from "react-bootstrap/Stack";
import { Carousel } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { Nav } from "react-bootstrap";
import { chunk } from "lodash";
import { useState, useEffect } from "react";
import { getBaseUrl, getSharePointUrl } from "../../config/environment";
import { getData } from "../../services/apiService";
import { cardArrow } from "../../utils/iconUtils";
import { useLocation } from "react-router";
import Loader from "../Loader/Loader";

const NewsTileComponent = ({ props, fullSize }) => {
  let location = useLocation();
  const [loader, setLoader] = useState(true);
  const [news, setNews] = useState(null);

  useEffect(() => {
    (async () => {
      const url = `${getSharePointUrl()}${props["source-url"]}`;

      const resp = await getData(url, "sharePointAccessToken");

      setNews(chunk(resp.data.value, fullSize ? 4 : 2));
      // toCheckPathOfUrl(resp.data);
      const root = document.documentElement;
      root?.style.setProperty(
        props.name.toLowerCase().includes("company")
          ? "--company-card-width"
          : "--fings-card-width",
        fullSize ? "25%" : "50%"
      );
    })();

    // eslint-disable-next-line
  }, [loader]);

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  useEffect(() => {
    toCheckPathOfUrl(news);
    // eslint-disable-next-line
  }, []);

  // eslint-disable-next-line
  async function toCheckPathOfUrl(data) {
    if (data) {
      const comNews = "sites/Communications-News/_api/Web";
      let propsData = data.value;

      let urlPromises = [];
      let blobPromises = [];
      let blobUrlArray = [];

      let finalUrl = "";

      propsData.forEach((element) => {
        if (
          element["BannerImageUrl"]["Url"].includes(`${getSharePointUrl()}`)
        ) {
          if (element["BannerImageUrl"]["Url"].includes("path")) {
            const imageUrl = decodeURI(element["BannerImageUrl"]["Url"])
              .split("path=")
              ?.pop();
            const decodeUrl = decodeURIComponent(imageUrl);

            finalUrl = `${getSharePointUrl()}/${comNews}/GetFileByServerRelativeUrl(%27${decodeUrl}%27)/$value`;
            try {
              urlPromises.push(
                fetch(finalUrl, {
                  headers: {
                    Authorization: `Bearer ${sessionStorage.sharePointAccessToken}`,
                  },
                }).catch((err) => {
                  return err;
                })
              );
            } catch (err) {
              return err;
            }
          } else {
            const imageUrl = element["BannerImageUrl"]["Url"]
              .split("sharepoint.com")
              ?.pop();
            finalUrl = `${getSharePointUrl()}/${comNews}/GetFileByServerRelativeUrl(%27${imageUrl}%27)/$value`;
            try {
              urlPromises.push(
                fetch(finalUrl, {
                  headers: {
                    Authorization: `Bearer ${sessionStorage.sharePointAccessToken}`,
                  },
                }).catch((err) => {
                  return err;
                })
              );
            } catch (err) {
              return err;
            }
          }
        } else {
          urlPromises.push(element["BannerImageUrl"]["Url"]);
        }
      });

      Promise.allSettled(urlPromises)
        .then((results) => {
          createBlobForEachUrl(results)
            .then(function (res) {
              res.forEach((elem) => {
                if (typeof elem === "object") {
                  blobUrlArray.push(URL.createObjectURL(elem));
                } else {
                  blobUrlArray.push(elem);
                }
              });

              if (blobUrlArray.length > 0) {
                propsData.forEach((elem) => {
                  elem["BannerImageUrl"]["Url"] = blobUrlArray.shift();
                });

                data.value = [...propsData];
                setLoader(false);
              }
            })
            .catch((err) => {
              return err;
            });
        })
        .catch((err) => {
          return err;
        });

      async function createBlobForEachUrl(blobResp) {
        for (var i = 0; i <= blobResp.length - 1; i++) {
          if (
            blobResp[i].status === "fulfilled" &&
            typeof blobResp[i].value === "object" &&
            blobResp[i].value.status === 200
          ) {
            blobPromises.push(await blobResp[i].value.blob());
          } else if (
            blobResp[i].value &&
            typeof blobResp[i].value === "string"
          ) {
            blobPromises.push(blobResp[i].value);
          } else {
            blobPromises.push(
              `${getBaseUrl()}/images/default-images/default-news.jpg`
            );
          }
        }

        return blobPromises;
      }
      data.value = [...propsData];
    }
  }

  return (
    <div className="w-100">
      <div id="news-section" className="w-100">
        <div className="newscomp mt-3 mb-3">
          <div className="news-header d-flex flex-column  my-2">
            <div>
              <h4 className="sec-title fw-bold">{props.title}</h4>
            </div>
            {props["display-show-all"] ? (
              <div>
                <a
                  className="app-tertiary-btn"
                  href={getSharePointUrl().concat(props["display-all-url"])}
                  target="_blank"
                  rel="noreferrer"
                >
                  {props["show-all-text"]}
                </a>
              </div>
            ) : null}
          </div>
          <div></div>
          {loader === true && news !== null ? (
            <div
              className={
                props.name.toLowerCase().includes("company")
                  ? "company-newscomp-carousel banner-carousel"
                  : "fings-newscomp-carousel banner-carousel"
              }
              id="news-carousel"
            >
              <Carousel interval={null} wrap={false} controls={false}>
                {news.map((record, i) => (
                  <Carousel.Item key={i}>
                    <Stack
                      direction="horizontal"
                      className="h-100 align-items-center"
                      gap={2}
                    >
                      {news[i].map((record, i) => (
                        <Card key={i}>
                          <Nav.Link
                            href={
                              window.location.hostname.includes("local") ||
                              window.location.hostname.includes(
                                ".mosaic-dev."
                              ) ||
                              window.location.hostname.includes(".mosaic-qa.")
                                ? `${getSharePointUrl().concat(
                                    props["display-all-url"]
                                  )}`
                                : `${getSharePointUrl()}${record?.FileRef}`
                            }
                            target="_blank"
                          >
                            <div className="img-hover-zoom">
                              <Card.Img
                                variant="bottom"
                                src={`${getBaseUrl()}/images/default-images/default-news.jpg`}
                                // src={
                                //   record.BannerImageUrl.Url !== null
                                //     ? record.BannerImageUrl.Url
                                //     : `${getBaseUrl()}/images/default-images/default-news.jpg`
                                // }
                                // alt={record.Title}
                              />
                            </div>
                            <Card.Body>
                              <Card.Subtitle className="mb-2 text-muted">
                                {record.NewsType}
                              </Card.Subtitle>
                              <Card.Title title={record.Title}>
                                {record.Title}
                              </Card.Title>
                              <Card.Text title={record.Description}>
                                {record.Description}
                              </Card.Text>
                              <footer className="blockquote-footer">
                                <div className="card-author">
                                  by{" "}
                                  {record?.Author?.Title
                                    ? record?.Author?.Title
                                    : "Author"}
                                </div>
                                <div className="card-arrow">{cardArrow}</div>
                              </footer>
                            </Card.Body>
                          </Nav.Link>
                        </Card>
                      ))}
                    </Stack>
                  </Carousel.Item>
                ))}
              </Carousel>
            </div>
          ) : news && loader === false ? (
            <div
              className={
                props.name.toLowerCase().includes("company")
                  ? "company-newscomp-carousel banner-carousel"
                  : "fings-newscomp-carousel banner-carousel"
              }
              id="news-carousel"
            >
              <Carousel interval={null} wrap={false} controls={false}>
                {news.map((record, i) => (
                  <Carousel.Item key={i}>
                    <Stack
                      direction="horizontal"
                      className="h-100 align-items-center"
                      gap={2}
                    >
                      {news[i].map((record, i) => (
                        <Card key={i}>
                          <Nav.Link
                            href={
                              window.location.hostname.includes("local") ||
                              window.location.hostname.includes(
                                ".mosaic-dev."
                              ) ||
                              window.location.hostname.includes(".mosaic-qa.")
                                ? `${getSharePointUrl().concat(
                                    props["display-all-url"]
                                  )}`
                                : `${getSharePointUrl()}${record?.FileRef}`
                            }
                            target="_blank"
                          >
                            <div className="img-hover-zoom">
                              <Card.Img
                                variant="bottom"
                                src={`${getBaseUrl()}/images/default-images/default-news.jpg`}
                                // src={record.BannerImageUrl.Url}
                                // alt={record.Title}
                                // onError={(event) => {
                                //   event.target.src = `${getBaseUrl()}/images/default-images/default-news.jpg`;
                                //   event.onerror = null;
                                // }}
                              />
                            </div>
                            <Card.Body>
                              <Card.Subtitle className="mb-2 text-muted">
                                {record.NewsType}
                              </Card.Subtitle>
                              <Card.Title title={record.Title}>
                                {record.Title}
                              </Card.Title>
                              <Card.Text title={record.Description}>
                                {record.Description}
                              </Card.Text>
                              <footer className="blockquote-footer">
                                <div className="card-author">
                                  by{" "}
                                  {record?.Author?.Title
                                    ? record?.Author?.Title
                                    : "Author"}
                                </div>
                                <div className="card-arrow">{cardArrow}</div>
                              </footer>
                            </Card.Body>
                          </Nav.Link>
                        </Card>
                      ))}
                    </Stack>
                  </Carousel.Item>
                ))}
              </Carousel>
            </div>
          ) : (
            <div style={{ maxWidth: "100%", height: "24rem" }}>
              <Loader />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewsTileComponent;
