import {
  MsalProvider,
  useIsAuthenticated,
  useMsal,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import "bootstrap/dist/css/bootstrap.min.css";
import NavbarComp from "./components/NavbarComp/NavbarComp";
import Footer from "./components/Footer/Footer";
import MainLandscape from "./pages/MainLandscape";
import FinGSLandscape from "./pages/FinGSLandscape";
import FavoritesPage from "./pages/FavoritesPage";
import UserManagement from "./pages/UserManagement";
import ApplicationsPage from "./pages/ApplicationsPage";
import EmpRelationPage from "./pages/EmpRelationPage";
import ApplicationsAdmin from "./pages/AdminScreens/ApplicationsAdmin";
import ToolsPage from "./pages/ToolsPage";
import OrganizationPage from "./pages/OrganizationPage";
import ManageTools from "./pages/ManageTools";
import TrainingsAdmin from "./pages/AdminScreens/TrainingsAdmin";
import TrainingsPage from "./pages/TrainingsPage";
import "../src/assets/scss/common.scss";
import { useEffect, useState, useMemo } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { getData, patchData } from "./services/apiService";
import { getBaseUrl } from "./config/environment";
import { UserContext } from "./contexts/UserContext";
import { ApiProvider } from "jsonapi-react";
import { getJsonApiClient } from "./services/jsonApiService";
import { configureTokens } from "./services/authService";
import Loader from "../src/components/Loader/Loader";
import { getHostUrl } from "./config/environment";
import CommunityPage from "./pages/CommunityPage";
import ManageCommunity from "./pages/ManageCommunity";
import ManageProcess from "./pages/ManageProcess";
import ProcessPage from "./pages/ProcessPage";
import ChatBotUI from "./components/ChatBot/ChatBotUI";
import Home from "./pages/Home";
import { defaultUserConfig } from "./config/Constants";

function App({ msalInstance }) {
  return (
    <MsalProvider instance={msalInstance}>
      <Pages />
    </MsalProvider>
  );
}

const Pages = () => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [isProfileLoaded, setProfileLoaded] = useState(false);
  const [user, setUser] = useState(null);
  const [client, setClient] = useState(null);
  const [emailId, setEmailId] = useState(null);
  const [wernerConfig, setWernerConfig] = useState([]);
  const userData = useMemo(() => ({ user, setUser }), [user, setUser]);

  async function getUserData(userId) {
    const res = await getData(`${getHostUrl()}user/check/${userId}`);
    sessionStorage.setItem("adminCheck", res.data);
  }

  useEffect(() => {
    (async () => {
      const resData = await getData(
        `${getBaseUrl()}/configurations/wernerConfig.json`
      );
      setWernerConfig(resData.data);
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const loadProfile = async () => {
      await configureTokens(instance);

      const profile = await getData(
        `${getHostUrl()}user/profile?include=employee_data`,
        "idToken"
      );
      const finalProfile = {
        displayName: `${profile.data.data.attributes["name"]}`,
        givenName: `${profile.data.data.attributes["first-name"]}`,
        surname: `${profile.data.data.attributes["last-name"]}`,
        userPrincipalName: `${profile.data.data.attributes["unique-name"]}`,
        id: `${profile.data.data.attributes["user-id"]}`,
        orgId: `${profile.data.included[0].attributes["org-id"]}`,
        orgFlag: profile.data.included[0].attributes["employee-org-master"].data
          .attributes["org-chart-flag"]
          ? profile.data.included[0].attributes["employee-org-master"].data
              .attributes["org-chart-flag"]
          : null,
        userId: `${profile.data.data.id}`,
        employeeId: `${profile.data.included[0].id}`,
        profilePicture: `${profile.data.included[0].attributes["profile-picture"]}`,
      };

      setUser(finalProfile);
      await getUserData(finalProfile.id);
      sessionStorage.setItem("userData", JSON.stringify(finalProfile));
      await setEmailId(finalProfile.userPrincipalName);
      await setClient(getJsonApiClient());

      const userConfig = await getData(`${getHostUrl()}user/config`, "idToken");
      let tempConfig = userConfig?.data.data.attributes.configuration;

      if (
        !tempConfig?.row ||
        !tempConfig?.homeConfig ||
        !tempConfig?.lunchData
      ) {
        const url = `${getHostUrl()}user/config`;
        const payLoad = {
          data: {
            type: "user",
            id: JSON.parse(sessionStorage.getItem("userData")).userId,
            attributes: {
              configuration: {
                row: tempConfig?.row
                  ? tempConfig?.row
                  : defaultUserConfig?.configuration?.row,
                homeConfig: tempConfig?.homeConfig
                  ? tempConfig?.homeConfig
                  : defaultUserConfig?.configuration.homeConfig,
                lunchData: tempConfig?.lunchData
                  ? tempConfig?.lunchData
                  : defaultUserConfig?.configuration?.lunchData,
                sacLinks: tempConfig?.sacLinks
                  ? tempConfig?.sacLinks
                  : defaultUserConfig?.configuration?.sacLinks,
              },
            },
          },
        };
        const headers = {
          Authorization: `Bearer ${sessionStorage.getItem("idToken")}`,
        };

        try {
          // eslint-disable-next-line
          const patchConfig = await patchData(url, payLoad, headers);
          sessionStorage.setItem(
            "userConfig",
            JSON.stringify(patchConfig?.data.data.attributes.configuration)
          );
        } catch (error) {
          console.error(error);
        }
      } else {
        sessionStorage.setItem(
          "userConfig",
          JSON.stringify(tempConfig && tempConfig)
        );
      }
      setProfileLoaded(true);
    };

    if (!isAuthenticated) {
      (async function () {
        try {
          const authResponse = await instance.ssoSilent({
            scopes: ["https://api.yammer.com/user_impersonation"],
          });
          instance.setActiveAccount(authResponse.account);
          await loadProfile();
        } catch (error) {
          if (error instanceof InteractionRequiredAuthError) {
            instance.loginRedirect();
          }
        }
      })();
    } else {
      (async function () {
        try {
          if (!sessionStorage.getItem("allTokensLoaded")) {
            await loadProfile();
          } else {
            setUser(JSON.parse(sessionStorage.getItem("userData")));
            await setEmailId(
              JSON.parse(sessionStorage.getItem("userData")).userPrincipalName
            );
            let checkUser = JSON.parse(sessionStorage.getItem("userData"));
            await getUserData(checkUser.id);
            const userConfig = await getData(
              `${getHostUrl()}user/config`,
              "idToken"
            );

            let tempConfig = userConfig?.data.data.attributes.configuration;
            if (
              !tempConfig?.row ||
              !tempConfig?.homeConfig ||
              !tempConfig?.lunchData
            ) {
              const url = `${getHostUrl()}user/config`;
              const payLoad = {
                data: {
                  type: "user",
                  id: JSON.parse(sessionStorage.getItem("userData")).userId,
                  attributes: {
                    configuration: {
                      row: tempConfig?.row
                        ? tempConfig?.row
                        : defaultUserConfig?.configuration?.row,
                      homeConfig: tempConfig?.homeConfig
                        ? tempConfig?.homeConfig
                        : defaultUserConfig?.configuration.homeConfig,
                      lunchData: tempConfig?.lunchData
                        ? tempConfig?.lunchData
                        : defaultUserConfig?.configuration?.lunchData,
                      sacLinks: tempConfig?.sacLinks
                        ? tempConfig?.sacLinks
                        : defaultUserConfig?.configuration?.sacLinks,
                    },
                  },
                },
              };
              const headers = {
                Authorization: `Bearer ${sessionStorage.getItem("idToken")}`,
              };

              try {
                // eslint-disable-next-line
                const patchConfig = await patchData(url, payLoad, headers);
                sessionStorage.setItem(
                  "userConfig",
                  JSON.stringify(
                    patchConfig?.data.data.attributes.configuration
                  )
                );
              } catch (error) {
                console.error(error);
              }
            } else {
              sessionStorage.setItem(
                "userConfig",
                JSON.stringify(tempConfig && tempConfig)
              );
            }
            await setClient(getJsonApiClient());
            setProfileLoaded(true);
          }
        } catch (error) {
          console.log(error);
          instance.logoutRedirect();
        }
      })();
    }
    const interval = setInterval(async () => {
      let currentTime = new Date();
      let expTime = new Date(sessionStorage.getItem("tokenExpiry"));
      expTime.setSeconds(expTime.getSeconds() + 2);

      if (currentTime > expTime) {
        try {
          const token = await instance.acquireTokenSilent({
            account: instance.getActiveAccount(),
            scopes: ["https://api.yammer.com/user_impersonation"],
          });

          if (sessionStorage.getItem("accessToken") !== token.accessToken) {
            sessionStorage.setItem("accessToken", token.accessToken);
            sessionStorage.setItem("idToken", token.idToken);
            sessionStorage.setItem("tokenExpiry", token.expiresOn);
            sessionStorage.setItem(
              "expiryDuration",
              Math.trunc(
                (new Date(sessionStorage.getItem("tokenExpiry")) - new Date()) /
                  1000
              )
            );
            await setClient(getJsonApiClient());
          }
        } catch (err) {
          if (err) {
            instance.loginRedirect();
          }
        }
      }
    }, 30000);
    return () => clearInterval(interval);

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <AuthenticatedTemplate>
        {isProfileLoaded ? (
          <ApiProvider client={client}>
            <Router>
              <UserContext.Provider value={userData}>
                <div className="App">
                  <NavbarComp />
                  <div>
                    <Routes>
                      <Route path="/" element={<Home />} exact />

                      <Route path="/favorites" element={<FavoritesPage />} />
                      <Route
                        path="/manage_users"
                        element={<UserManagement />}
                      />
                      <Route path="/manage_tools" element={<ManageTools />} />
                      <Route
                        path="/manage_communities"
                        element={<ManageCommunity />}
                      />
                      <Route
                        path="/manage_trainings"
                        element={<TrainingsAdmin />}
                      />

                      <Route path="/landscape" element={<MainLandscape />}>
                        <Route index element={<FinGSLandscape />} />
                        <Route
                          path="/landscape/v2/applications"
                          element={<ApplicationsPage />}
                        />
                        <Route
                          path="/landscape/applications"
                          element={<ApplicationsPage />}
                        />
                        <Route
                          path="/landscape/communities"
                          element={<CommunityPage />}
                        />
                        <Route
                          path="/landscape/v2/tools"
                          element={<ToolsPage />}
                        />
                        <Route
                          path="/landscape/tools"
                          element={<ToolsPage />}
                        />
                        <Route
                          path="/landscape/v2/trainings"
                          element={<TrainingsPage />}
                        />
                        <Route
                          path="/landscape/trainings"
                          element={<TrainingsPage />}
                        />
                        <Route
                          path="/landscape/v2/organization"
                          element={<OrganizationPage />}
                        />
                        <Route
                          path="/landscape/organization"
                          element={<OrganizationPage />}
                        />
                        <Route
                          path="/landscape/process"
                          element={<ProcessPage />}
                        />
                      </Route>
                      <Route
                        path="/manage_applications"
                        element={<ApplicationsAdmin />}
                      />
                      <Route
                        path="/manage_process"
                        element={<ManageProcess />}
                      />
                      <Route
                        path="/employee_relation"
                        element={<EmpRelationPage />}
                      />
                    </Routes>
                    {wernerConfig?.visible?.toLowerCase() === "all" ? (
                      <ChatBotUI />
                    ) : wernerConfig?.visible?.toLowerCase() === "admins" &&
                      emailId &&
                      wernerConfig?.data?.includes(emailId) ? (
                      <ChatBotUI />
                    ) : null}
                  </div>
                  <Footer />
                </div>
              </UserContext.Provider>
            </Router>
          </ApiProvider>
        ) : (
          <div style={{ maxWidth: "100%", height: "100vh" }}>
            <Loader />
          </div>
        )}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <div style={{ maxWidth: "100%", height: "100vh" }}>
          <Loader />
        </div>
      </UnauthenticatedTemplate>
    </>
  );
};

export default App;
